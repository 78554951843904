<template>
  <TTView>
    <VRow>
      <VCol>
        <h1 class="black--text">
          {{ entity.title }}
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VForm
          ref="form"
        >
          <VContainer fluid>
            <VRow
              align="center"
            >
              <VCol md="6">
                <div>
                  <strong>Приложение</strong>
                </div>
                <VSelect
                  v-model="entity.appName"
                  :items="apps"
                  item-text="name"
                  item-value="name"
                />
              </VCol>
            </VRow>
            <VRow
              align="center"
            >
              <VCol md="6">
                <div>
                  <strong>Лицензент</strong>
                </div>
                <VSelect
                  v-model="entity.licensee.companyId"
                  :items="companies"
                  label="Компания"
                  item-text="name"
                  item-value="id"
                />
                <VSelect
                  v-model="entity.licensee.accountId"
                  :items="accounts"
                  label="Аккаунт"
                  item-text="name"
                  item-value="id"
                />
              </VCol>
            </VRow>
            <VRow
              align="center"
            >
              <VCol md="6">
                <div>
                  <strong>Длительность</strong>
                </div>
                <VTextField
                  v-model.number="entity.duration.days"
                  label="Дней"
                  type="tel"
                />
                <h3>Начало</h3>
                <VDatePicker v-model="entity.duration.startsAt" />
              </VCol>
            </VRow>
            <VRow
              align="center"
            >
              <VCol md="6">
                <div>
                  <strong>Пользователи</strong>
                </div>
                <VTextField
                  v-model.number="entity.totalUsers"
                  label="Всего"
                  type="tel"
                />
                <VTextField
                  v-model.number="entity.involvedUsers"
                  label="Активные пользователи (входили хотя бы раз)"
                  type="tel"
                />
              </VCol>
            </VRow>
            <VRow
              align="center"
            >
              <VCol md="6">
                <VCheckbox
                  :input-value="entity.active"
                  label="Активный"
                  @change="changeActivity"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol md="4">
                <VBtn
                  large
                  text
                  depressed
                  @click="$router.push({ name : Names.R_LICENSES })"
                >
                  Отмена
                </VBtn>
              </VCol>
            </VRow>
          </VContainer>
        </VForm>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'LicenseItem',
  inject: ['Names'],
  data() {
    return {
      entity: {
        appName: 'string',
        licensee: {
          accountId: 'string',
          companyId: 'string',
        },
        duration: {
          startsAt: '',
          days: 0,
        },
        totalUsers: 0,
        involvedUsers: 0,
        active: true,
      },
      apps: [],
      accounts: [],
      companies: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.$di.api.License.getLicense({ id: this.id })
      .then((r) => {
        const response = r.licence;
        response.duration.startsAt = this.$dayjs(response.duration.startsAt)
          .format('YYYY-MM-DD');
        this.entity = response;
      })
      .catch(this.$di.notify.errorHandler);

    this.$di.api.License.indexApp()
      .then((r) => {
        this.apps = r.apps;
      })
      .catch(this.$di.notify.errorHandler);

    this.$di.api.Account.indexAccount()
      .then((r) => {
        this.accounts = r.accounts;
      })
      .catch(this.$di.notify.errorHandler);

    this.$di.api.Account.indexCompany()
      .then((r) => {
        this.companies = r.companies;
      })
      .catch(this.$di.notify.errorHandler);
  },
  methods: {
    cancel() {
      this.$refs.form.reset();
    },
    save() {
      const license = { ...this.entity };
      this.$di.api.License.updateLicense({ license })
        .catch(this.$di.notify.errorHandler);
    },
    changeActivity(e) {
      if (!e) {
        return this.$di.api.License.deactivateLicense({ id: this.id })
          .then(() => this.$di.notify.snackSuccess('Лицензия деактивирована'))
          .catch(this.$di.notify.errorHandler);
      }
      return this.$di.api.License.revokeLicense({
        appName: this.appName,
        licensee: this.licensee,
      })
        .then(() => this.$di.notify.snackSuccess('Лицензия реактивирована'))
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
